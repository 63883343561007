.App {
  min-height: 100vh;
  background-color: #f9f9f9;
  -webkit-background-size: fill;
  -moz-background-size: fill;
  -o-background-size: fill;
  background-size: fill;
}

.button-text {
  color: #d12487;
  display: block;
  height: 100%;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 1.2em 0.4em 1em 0.4em;
  font-family: Arial, arial, "Avenir", proxima-nova, sans-serif;
}

.black-title {
  font-size: 2rem;
  font-weight: 400;
  margin: 0 0 1rem 0;
  color: #000;
  line-height: 1.4;
  font-family: "Avenir", proxima-nova, sans-serif;
  text-align: center;
  text-shadow: 2px 2px 4px #000000;
}

.lite {
  font-family: "Avenir", proxima-nova, sans-serif;
  color: #6e2d79;
  font-size: 1.3rem;
}
.p {
  font-family: Arial, arial, "Avenir", proxima-nova, sans-serif;
  font-weight: 400;
  letter-spacing: 0.05em;

  /* background: #333; */
}

.yellow-bg {
  background: #f1cd39 none repeat scroll 0 0;
  display: inline-block;
  margin: 15px 0 -22px 0;
  padding: 10px 10px 10px 10px;
  width: 100%;
  height: 88px;
}

.row-sm {
  width: 1%;
  white-space: nowrap;
}

.left {
  float: left !important;
}

.right {
  float: right !important;
}

.btn-yellow {
  border: 2px solid #000;
  color: #000;
  display: inline-block;
  font-size: 11pt;
  font-weight: bold;
  /* margin-top: 17px;
  padding: 11px 0; */

  text-transform: uppercase;
  width: 100%;
  height: 50px;
  background: #f1cd39 none repeat scroll 0 0;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  font-family: "qt_zaintext_bold_pcbold", Arial, "Helvetica Neue", Helvetica,
    sans-serif !important;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tableMid td {
  vertical-align: middle !important;
}
.profile-card {
  background-color: #2e3338;
  background-image: linear-gradient(#cf0072 0%, #2e3338 75%);
}
.cart-bg {
  background-image: linear-gradient(#e4d700 0%, #cf0072 51%, #2e3338 75%);
}
.custom-nav {
  background-color: #6e2c79;
}
.dropdown-item:hover {
  background-color: #bb79c6;
}

.bg-primary {
  background-color: #7ec2e7 !important;
}
.box {
  width: 100px;
  height: 100px;
  background: #ff1c68;
  transform-origin: 50% 50%;
}

.dot {
  box-shadow: 10px 10px 10px rgba(161, 0, 246, 0.2) !important;
  height: 170px;
  width: 170px;
  background-color: #6a5d97 !important;
  border-radius: 50%;
  display: inline-block;
}

.badge-success {
  background-color: #2faa86;
}
.bt-success {
  background-color: #69be28;
}
.title {
  background-color: rgb(140, 195, 68);
}

.collapse-title:hover {
  background-color: #d3d3d3;
}

.collapse-title {
  background-color: #f9f9f9;
}

.opened-title {
  background-color: #d3d3d3;
}

.opened-title:hover {
  background-color: #c0c0c0;
}

.category_card {
  overflow-x: hidden;
  overflow-y: hidden;
  height: 1%;
}

.table-rtl {
  direction: rtl;
}

.dialog {
  direction: ltr;
}

.size {
  font-size: 0.9rem;
}

.sm-font-size {
  font-size: 12px;
}
